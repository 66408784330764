<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" align="center" v-bind:class="mobile ? 'firsSectionMobile' : 'firsSection'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <h3 class="subtitle" v-html="$t('subtitle')"></h3>
        <div class="text" v-html="$t('text')"></div>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <v-img
            v-bind:class="mobile ? '' : 'imgFirstSection'"
            :src="require('@/assets/enterprise/enterprise2.png')"
            contain
            v-bind:height="mobile ? 250 : 350"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'FormationGesturePosture',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Company gestures and postures training",
    "subtitle": "Goals :",
    "text": "- Know the risks associated with professional activity <br> - Apply the principles of safety <br> - Apply the techniques of gestures and work postures to reduce the frequency of accidents and reduce fatigue through the principles of economy of efforts or reduction of sedentary lifestyle <br> - Optimizing the organization of work to combine efficiency and prevention"
  },
  "fr": {
    "mainTitle": "Formation gestes et postures entreprise",
    "subtitle": "Objectifs :",
    "text": "- Connaitre les risques liés à l'activité professionnelle <br> - Appliquer les principes de sécurité <br> - Appliquer les techniques de gestes et postures de travail pour diminuer la fréquence des accidents et atténuer la fatigue par les principes d'économie d'efforts ou de réduction de la sédentarité  <br> - Optimiser l'organisation du travail pour allier efficacité et prévetion"
  }
}
</i18n>

<style scoped>

.subtitle {
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
}

.text {
  padding-top: 20px;
  color: #808080;
}

.imgFirstSection {
  margin-right: 60px;
}

.firsSection {
  padding-left: 200px;
  padding-right: 200px;
}

.firsSectionMobile {
  font-size: 14px;
}

.firsSection {
  padding-left: 200px;
  padding-right: 200px;
}

</style>
