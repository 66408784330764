<template>
  <div>
    <v-row justify="space-around" v-bind:class="mobile ? '' : 'firstLine'" no-gutters>
      <v-btn v-bind:class="mobile ? 'redirectBtnEnterpriseMobile' : 'redirectBtnEnterprise'"
             rounded
             height="65px"
             dark
             v-html="$t(links[0].title)"
             @click="$router.push({name: links[0].name}).then(() => {$vuetify.goTo(0)})"
             color="#57939E">
      </v-btn>
      <v-btn v-bind:class="mobile ? 'redirectBtnEnterpriseMobile' : 'redirectBtnEnterprise'"
             rounded
             height="65px"
             dark
             v-html="$t(links[1].title)"
             @click="$router.push({name: links[1].name}).then(() => {$vuetify.goTo(0)})"
             color="#57939E">
      </v-btn>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? '' : 'secondLine'" no-gutters>
      <v-btn v-bind:class="mobile ? 'redirectBtnEnterpriseMobile' : 'redirectBtnEnterprise'"
             v-bind:style="mobile ? 'margin-bottom: 40px;' : ''"
             rounded
             height="65px"
             dark
             v-html="$t(links[2].title)"
             @click="$router.push({name: links[2].name}).then(() => {$vuetify.goTo(0)})"
             color="#57939E">
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'RedirectEnterpriseComp',
  props: ['mobile'],
  data: () => ({
    links: [
      {
        title: "awarenessDay",
        name: "AwarenessDay"
      },
      {
        title: "conferenceDiagnosis",
        name: "ConferenceDiagnosis"
      },
      {
        title: "physicalActivities",
        name: "PhysicalActivitiesEnterprise"
      },
      {
        title: "formationGesturePosture",
        name: "FormationGesturePosture"
      }
    ]
  }),
  created() {
    // remove from array current route
    let currentRoute = this.$route.name
    let indexForCurrentRoute = this.links.findIndex(link => link.name === currentRoute);
    this.links.splice(indexForCurrentRoute, 1);
  }
};
</script>

<i18n>
{
  "en": {
    "awarenessDay": "Corporate awareness day",
    "conferenceDiagnosis": "Conferences and business <br> diagnosis",
    "physicalActivities": "Adapted physical activities <br> offered in companies",
      "formationGesturePosture": "Training gestures and postures <br> for the company"
  },
  "fr": {
    "awarenessDay": "Journée de sensibilisation <br> entreprise",
    "conferenceDiagnosis": "Conférences et diagnostic <br> entreprise",
    "physicalActivities": "Les activités physiques adaptées <br> proposées en entreprise",
    "formationGesturePosture": "Formation gestes et postures <br> entreprise"
  }
}
</i18n>

<style scoped>

.redirectBtnEnterprise {
  width: 550px;
  font-size: 18px;
  text-transform: none;
}

.redirectBtnEnterpriseMobile {
  margin-top: 40px;
  width: 330px;
  font-size: 15px;
  text-transform: none;
}


.firstLine {
  padding-top: 100px;
}

.secondLine {
  padding-top: 75px;
  padding-bottom: 100px;
}

</style>
